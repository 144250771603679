<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <button
              type="button"
              class="btn btn-secondary mx-1"
              @click="openSendCredentials"
            >
              <span class="me-25 align-middle">Send credentials</span>
              <i data-feather="send" />
            </button>
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'admin.users.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="users"
              :sort-mapping="sortMapping"
              :title="'users'"
              :total="usersTotal"
              :fetch-path="'users/fetchUsers'"
              :filter-path="'users/filterManagement'"
              :export-path="'users/export'"
              @appliedFilters="appliedFilters"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'admin.users.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i data-feather="edit-3" />
                        Edit user
                      </b-dropdown-item>
                      <b-dropdown-item @click="prefilterUser(field.id)">
                        <i data-feather="eye" />
                        View user
                      </b-dropdown-item>
                      <b-dropdown-item @click="fusionUser(field.id)">
                        <i data-feather="users" />
                        Fusion user
                      </b-dropdown-item>
                      <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View traces
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i data-feather="trash-2" />
                        Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameuser"
                    class="form-label"
                  >Name</label>
                  <v-select
                    v-model="filters.user"
                    label="name"
                    :placeholder="'Type 3 or more characters to search...'"
                    :options="auxUsers"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Name: ${$event ? $event.name : ''}`, value: $event }, 'user')"
                    @search="onSearch({ name: $event }, 'users/fetchAuxUsers')"
                  />
                </div>
                <!-- <div class="mb-1">
                  <label
                    for="emailuser"
                    class="form-label"
                  >Email</label>
                  <input
                    v-model="filters.email"
                    type="text"
                    class="form-control"
                    name="emailuser"
                    @change="saveFilter({ customLabel: true, name: `Email: ${$event.target.value}`, value: `${$event.target.value}` }, 'email')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="phoneuser"
                    class="form-label"
                  >Phone</label>
                  <input
                    v-model="filters.phone"
                    type="text"
                    class="form-control"
                    name="phoneuser"
                    @change="saveFilter({ customLabel: true, name: `Phone: ${$event.target.value}`, value: `${$event.target.value}` }, 'phone')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="workphoneuser"
                    class="form-label"
                  >Work Phone</label>
                  <input
                    v-model="filters.work_phone"
                    type="text"
                    class="form-control"
                    name="workphoneuser"
                    @change="saveFilter({ customLabel: true, name: `Work phone: ${$event.target.value}`, value: `${$event.target.value}` }, 'work_phone')"
                  >
                </div> -->
                <div class="mb-1">
                  <label
                    for="workphoneuser"
                    class="form-label"
                  >Keyword</label>
                  <input
                    type="text"
                    class="form-control"
                    name="workphoneuser"
                    @change="saveFilter({ customLabel: true, name: `Keyword: ${$event.target.value}`, value: $event.target.value}, 'keywords')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Host</label>
                  <v-select
                    v-model="filters.host"
                    label="custom_name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Host: ${$event ? $event.custom_name : ''}`, value: $event }, 'host')"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                  />
                </div>
                <div
                  v-if="filters.host"
                  class="mb-1"
                >
                  <label
                    for=""
                    class="form-label"
                  >Department</label>
                  <v-select
                    v-model="filters.department"
                    label="name"
                    :options="filters.host.departments"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Department: ${$event ? $event.name : ''}`, value: $event }, 'department')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Roles</label>
                  <v-select
                    v-model="filters.roles"
                    label="name"
                    :options="roles"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Role: ${$event ? $event.name : ''}`, value: $event }, 'roles')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Area</label>
                  <v-select
                    v-model="filters.area"
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area')"
                    @search="onSearch({ name: $event }, 'areas/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Active</label>
                  <v-select
                    v-model="filters.active"
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Yes', value: true},
                      {label: 'No', name: 'No', value: false},
                      {label: 'All', name: 'All', value: 'all'},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Active: ${$event ? $event.name : ''}`, value: $event }, 'active')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Is test user</label>
                  <v-select
                    v-model="filters.test"
                    label="label"
                    :options="[
                      {label: 'Yes', name: 'Yes', value: true},
                      {label: 'No', name: 'No', value: false},
                      {label: 'All', name: 'All', value: 'all'},
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Is test user: ${$event ? $event.name : ''}`, value: $event }, 'test')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Calls</label>
                  <v-select
                    v-model="filters.convos"
                    label="title"
                    multiple
                    clearable
                    :options="convos"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Calls: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'convos')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Start year</label>
                  <date-picker
                    v-model="filters['start_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Start year: ${$event}`, value: `${$event}` }, 'start_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >End year</label>
                  <date-picker
                    v-model="filters['end_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `End year: ${$event}`, value: `${$event}` }, 'end_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Type of leave</label>
                  <v-select
                    v-model="filters.type_of_leave"
                    label="name"
                    :options="leaves"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Type of leave: ${$event.name}`, value: $event }, 'type_of_leave')"
                  />
                </div>
              </template>
            </CompleteTable>
            <ItemInfoSheet :table-id="sendCredentialsInfoSheet">
              <template #info-sheet-title>
                Send credentials to:
              </template>
              <template
                #info-sheet
              >
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id="flexCheckDefault"
                        v-model="recipients"
                        class="form-check-input"
                        value="all_users"
                        type="radio"
                        name="inlineRadioOptions"
                        checked
                      >
                      <label
                        class="form-check-label"
                        for="flexCheckDefault"
                      >Active users not in promotion</label>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id="flexCheckDefault"
                        v-model="recipients"
                        class="form-check-input"
                        value="under_promotion"
                        type="radio"
                        name="inlineRadioOptions"
                        checked
                      >
                      <label
                        class="form-check-label"
                        for="flexCheckDefault"
                      >Under promotion users</label>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id="flexCheckDefault"
                        v-model="recipients"
                        class="form-check-input"
                        value="isabel_test"
                        type="radio"
                        name="inlineRadioOptions"
                        checked
                      >
                      <label
                        class="form-check-label"
                        for="flexCheckDefault"
                      >Isabel test</label>
                    </div>
                  </div>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <button
                    v-if="!sending"
                    type="button"
                    class="btn btn-primary mb-1 w-100"
                    @click="sendCredentials"
                  >
                    <span class="me-25 align-middle">Send credentials</span>
                    <i data-feather="send" />
                  </button>
                  <b-button
                    v-else
                    type="submit"
                    disabled
                  >
                    <div class="loading">
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </b-button>
                </div>
              </template>
            </ItemInfoSheet>
          </div>
          <ManageUserTraces :user="traceUser" />
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    vSelect,
    ItemInfoSheet,
    DatePicker,
    BDropdown,
    BDropdownItem,
    ManageUserTraces,
  },
  data() {
    return {
      traceUser: {},
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Photo',
          checked: true,
          order: 1,
        },
        {
          name: 'Name',
          checked: true,
          order: 2,
        },
        {
          name: 'NIF/NIE',
          checked: true,
          order: 3,
        },
        {
          name: 'Email',
          checked: true,
          order: 4,
        },
        {
          name: 'Area',
          checked: true,
          order: 5,
        },
        {
          name: 'Main ERC',
          checked: true,
          order: 6,
        },
        {
          name: 'Host',
          checked: true,
          order: 7,
        },
        {
          name: 'Department',
          checked: true,
          order: 8,
        },
        {
          name: 'Keywords',
          checked: true,
          order: 9,
        },
        {
          name: 'Active',
          checked: true,
          order: 10,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 11,
        },
        {
          name: 'End date',
          checked: true,
          order: 12,
        },
        {
          name: 'Call',
          checked: true,
          order: 13,
        },
        {
          name: 'Roles',
          checked: true,
          order: 14,
        },
        {
          name: 'Last access',
          checked: true,
          order: 15,
        },
        {
          name: 'Type of leave',
          checked: true,
          order: 16,
        },
      ],
      sortMapping: {
        Photo: 'img_table',
        Name: 'name',
        'NIF/NIE': 'identify_document',
        Email: 'email',
        Area: 'areas',
        'Main ERC': 'erc.code',
        Host: 'hosts_table_index',
        Department: 'department_table_index',
        Active: 'active',
        'Begin date': 'researcher_start_date',
        'End date': 'end_at',
        Call: 'enter_call.title',
        Roles: 'roles_names',
        Keywords: 'keywords_table',
        'Last access': 'last_access',
        'Type of leave': 'type_of_leave.name',
      },
      title: 'Users',
      sendCredentialsInfoSheet: 1,
      sending: false,
      recipients: null,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'users/users',
      usersTotal: 'users/usersTotal',
      roles: 'roles/allRoles',
      auxUsers: 'users/auxUsers',
      areas: 'areas/areas',
      convos: 'convo/convos',
      hosts: 'institutions/institutions',
      filters: 'filters/filters',
      leaves: 'typeOfLeave/items',
    }),
    role() {
      return this.$route.params.role
    },
  },
  watch: {
    users() {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    role() {
      this.resetCard()
    },
  },
  async mounted() {
    this.$store.dispatch('convo/fetchAll', 'senior')
    this.$store.dispatch('typeOfLeave/fetch')
    this.resetCard()
  },
  methods: {
    async fusionUser(id) {
      await this.$store.dispatch('modals/saveReorganizeData', {
        deleteUrl: `${Vue.prototype.$groupUrl}/user/${id}`,
        deleteFetchName: 'users',
        deleteCustomMessage: 'The data has been transferred, do you want to delete this user?',
        itemId: id,
        itemsFetchName: 'users/fetchFakeUsersWithRoles',
        itemsStoreName: 'users/fakeUsers',
        title: 'user',
        removeDelete: true,
        hasRelationDispatch: {},
      })
      this.$store.dispatch('modals/showReorganizeModal', true)
    },
    async resetCard() {
      await this.$store.dispatch('modals/fetchUserFields', 'users')

      if (this.$route.query.host) {
        try {
          const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/${this.$route.query.host}`)
          this.saveFilter({ customLabel: true, name: `Host: ${data.data.name}`, value: data.data.name }, 'host')
        } catch (e) {
          console.log(e)
        }
      }

      if (this.$route.query.memoir) {
        try {
          const { data } = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs/filter`, { year: this.$route.query.memoir })
          const memoir = data.data.data[0]
          if (memoir) {
            this.saveFilter({ customLabel: true, name: `Memoir: ${memoir.title}`, value: memoir.year }, 'memoir')
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        await this.saveFilter({
          customLabel: true, name: 'Active: Yes', value: { label: 'Yes', name: 'Active', value: true },
        }, 'active')
      }

      await this.saveFilter({
        customLabel: true, name: 'Is test user: All', value: { label: 'All', name: 'All', value: 'all' },
      }, 'test')

      await this.$store.dispatch('roles/fetchAll')

      if (this.$route.params.role) {
        const r = this.roles.filter(e => e.name === this.role)
        if (r.length) {
          await this.saveFilter({ customLabel: true, name: `Role: ${r[0].name}`, value: { label: 'Roles', name: r[0].name, value: r[0].id } }, 'roles')
        }
      }

      if (this.$route.params.onPromotion) {
        const r = this.roles.filter(e => e.name === 'researcher')
        await this.saveFilter({ customLabel: true, name: 'Role: researcher', value: { label: 'Role', name: r[0].name, value: r[0].id } }, 'roles')
        await this.saveFilter({ customLabel: true, name: 'On promotion: Yes', value: { label: 'On promotion', name: 'Yes', value: this.$route.params.onPromotion } }, 'onPromotion')
      }

      await this.$store.dispatch('users/filterManagement', this.filters)

      if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
        await this.$store.dispatch('modals/fetchFields', {
          fields: this.defaultFields,
          table: 'users',
        })
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'users/fetchUsers', url: `${Vue.prototype.$groupUrl}/user/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async onSearch(search, store) {
      const s = search
      if (s.name !== '' && s.name.length > 2) {
        s.ignoreName = true
        await this.$store.dispatch(store, s)
      }
    },
    prefilterUser(user) {
      window.open(this.$router.resolve({ name: 'researcher.administrative-data.researcher', params: { id: user }, query: { prefilterUser: user } }).href, '_blank')
    },
    sendCredentials() {
      if (this.recipients) {
        Vue.swal({
          title: 'Do you want to send the emails?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(async result => {
          if (result.isConfirmed) {
            this.sending = true
            if (this.recipients) {
              this.$store.dispatch('users/sendUserCredentials', this.recipients).then(() => {
                this.sending = false
                Vue.swal(this.recipients === 'isabel_test' ? 'Credentials sended to Isabel Test!' : 'Credentials sended!', '', 'success')
                this.$store.dispatch('modals/toggleInfoItemSheet', false)
              }).catch(() => {
                this.sending = false
                // this.$toastr.error('', 'Error on send credentials')
                Vue.swal('Error sending credentials', '', 'error')
              })
            }
          }
        })
      } else {
        Vue.swal('', 'Please, fill up recipients', 'warning')
      }
      // this.sending = true
      // if (this.recipients) {
      //   this.$store.dispatch('users/sendUserCredentials', this.recipients).then(() => {
      //     this.sending = false
      //     Vue.swal(this.recipients === 'isabel_test' ? 'Credentials sended to Isabel Test!' : 'Credentials sended!', '', 'success')
      //     this.$store.dispatch('modals/toggleInfoItemSheet', false)
      //   }).catch(() => {
      //     this.sending = false
      //     // this.$toastr.error('', 'Error on send credentials')
      //     Vue.swal('Error sending credentials', '', 'error')
      //   })
      // }
      this.sending = false
    },
    appliedFilters() {
      if (!this.filters.active) {
        this.$toastr.warning('', 'Default filter is: All users')
        this.saveFilter({ customLabel: true, name: 'Active: All users', value: { name: 'Active', value: 'All', label: 'Yes' } }, 'active')
      }
    },
    openSendCredentials() {
      this.$store.dispatch('modals/toggleTableClicked', this.sendCredentialsInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>
